import React, { useState, useEffect } from 'react'
import { Button } from "../../components/ui/button"
import { Input } from "../../components/ui/input"
import { Label } from "../../components/ui/label"
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "../../components/ui/dialog"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table"
import { UploadCloud, LogIn, UserPlus, ChevronRight, Download, X, AlertCircle, RefreshCw, FileText, Zap, Lock } from 'lucide-react'
import axios from 'axios'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
const TEMPLATE_URL = 'https://objectstore.lon1.civo.com/gpg-pub/template.csv';

function Separator({ className = '', orientation = 'horizontal' }) {
  const baseStyles = 'bg-gray-200 dark:bg-gray-700'
  const orientationStyles = orientation === 'horizontal' ? 'h-px w-full' : 'h-full w-px'
  return (
    <div
      role="separator"
      aria-orientation={orientation}
      className={`${baseStyles} ${orientationStyles} ${className}`}
    />
  )
}

export default function LandingPage() {
  const [file, setFile] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [username, setUsername] = useState('')
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [calculationResults, setCalculationResults] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)
  const [showUpload, setShowUpload] = useState(true)
  const [isTemplateLoading, setIsTemplateLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [name, setName] = useState('')

  useEffect(() => {
    const token = localStorage.getItem('authToken')
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      verifyToken()
    }
  }, [])

  const verifyToken = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/verify-token`)
      setIsLoggedIn(true)
      setUsername(response.data.name)
    } catch (error) {
      console.error('Token verification failed:', error)
      handleLogout()
    }
  }

  const handleFileChange = async (event) => {
    if (event.target.files) {
      const selectedFile = event.target.files[0]
      setFile(selectedFile)

      if (!isLoggedIn) {
        const content = await selectedFile.text()
        const rows = content.split('\n').filter(row => row.trim() !== '')
        if (rows.length > 6) {
          setError('Free users can only upload files with up to 5 rows of data (excluding the header row).')
        } else {
          setError(null)
        }
      }
    }
  }

  const handleUpload = async () => {
    if (file) {
      setIsLoading(true)
      setError(null)
      const formData = new FormData()
      formData.append('file', file)

      try {
        const response = await axios.post(`${API_BASE_URL}/api/calculate`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          withCredentials: true
        })
        console.log('API Response:', response.data)
        setCalculationResults(response.data)
        setShowUpload(false)
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setError(`An error occurred: ${error.response?.data?.message || error.message}`)
        } else {
          setError('An error occurred while processing the file. Please try again.')
        }
        console.error('Upload error:', error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const handleTryAgain = () => {
    setFile(null)
    setCalculationResults(null)
    setError(null)
    setShowUpload(true)
  }

  const handleLogin = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    setError(null)
    try {
      const response = await axios.post(`${API_BASE_URL}/api/login`, { email, password })
      setIsLoggedIn(true)
      setUsername(response.data.name)
      setIsDialogOpen(false)
      localStorage.setItem('authToken', response.data.token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
    } catch (error) {
      setError(error.response?.data?.message || 'Login failed. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSignup = async (event) => {
    event.preventDefault()
    setIsLoading(true)
    setError(null)
    try {
      const response = await axios.post(`${API_BASE_URL}/api/signup`, { name, email, password })
      setIsLoggedIn(true)
      setUsername(response.data.name)
      setIsDialogOpen(false)
      localStorage.setItem('authToken', response.data.token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`
    } catch (error) {
      setError(error.response?.data?.message || 'Signup failed. Please try again.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleSocialLogin = async (provider) => {
    setIsLoading(true)
    setError(null)
    try {
      // Redirect to the backend route for social login
      window.location.href = `${API_BASE_URL}/api/auth/${provider}`
    } catch (error) {
      setError(`${provider} login failed. Please try again.`)
      setIsLoading(false)
    }
  }

  const handleLogout = async () => {
    try {
      await axios.post(`${API_BASE_URL}/api/logout`)
    } catch (error) {
      console.error('Logout error:', error)
    } finally {
      setIsLoggedIn(false)
      setUsername('')
      setCalculationResults(null)
      setFile(null)
      setShowUpload(true)
      localStorage.removeItem('authToken')
      delete axios.defaults.headers.common['Authorization']
    }
  }

  const handleDownloadExample = async () => {
    setIsTemplateLoading(true)
    setError(null)
    try {
      const response = await fetch(TEMPLATE_URL)
      if (!response.ok) {
        throw new Error('Failed to fetch the template')
      }
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'template.csv'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    } catch (error) {
      console.error('Error downloading template:', error)
      setError('Failed to download the template. Please try again.')
    } finally {
      setIsTemplateLoading(false)
    }
  }

  const formatValue = (value) => {
    if (typeof value === 'number') {
      return value.toFixed(2) + '%'
    }
    return value.toString()
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 text-gray-800">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <nav className="flex justify-between items-center">
            <h1 className="text-2xl font-bold text-blue-600">Gender Pay Gap Calculator</h1>
            <div className="space-x-4">
              {isLoggedIn ? (
                <>
                  <span>{username}</span>
                  <Button variant="outline" onClick={handleLogout}>Logout</Button>
                </>
              ) : (
                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                  <DialogTrigger asChild>
                    <Button variant="outline">Login / Sign Up</Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-[425px] bg-white text-gray-900">
                    <DialogHeader>
                      <DialogTitle className="text-2xl font-bold text-gray-900">Account</DialogTitle>
                      <DialogDescription className="text-gray-600">
                        Login or create a new account to access additional features.
                      </DialogDescription>
                    </DialogHeader>
                    <button
                      onClick={() => setIsDialogOpen(false)}
                      className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                    >
                      <X className="h-4 w-4" />
                      <span className="sr-only">Close</span>
                    </button>
                    <Tabs defaultValue="login" className="w-full">
                      <TabsList className="grid w-full grid-cols-2 bg-gray-100">
                        <TabsTrigger value="login" className="data-[state=active]:bg-white">Login</TabsTrigger>
                        <TabsTrigger value="signup" className="data-[state=active]:bg-white">Sign Up</TabsTrigger>
                      </TabsList>
                      <TabsContent value="login">
                        <form onSubmit={handleLogin} className="space-y-4">
                          <div>
                            <Label htmlFor="email" className="text-gray-700">Email</Label>
                            <Input
                              id="email"
                              type="email"
                              placeholder="m@example.com"
                              required
                              className="bg-white border-gray-300"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div>
                            <Label htmlFor="password" className="text-gray-700">Password</Label>
                            <Input
                              id="password"
                              type="password"
                              required
                              className="bg-white border-gray-300"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white">
                            <LogIn className="mr-2 h-4 w-4" /> Login
                          </Button>
                        </form>
                      </TabsContent>
                      <TabsContent value="signup">
                        <form onSubmit={handleSignup} className="space-y-4">
                          <div>
                            <Label htmlFor="name" className="text-gray-700">Name</Label>
                            <Input
                              id="name"
                              placeholder="John Doe"
                              required
                              className="bg-white border-gray-300"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>
                          <div>
                            <Label htmlFor="email" className="text-gray-700">Email</Label>
                            <Input
                              id="email"
                              type="email"
                              placeholder="m@example.com"
                              required
                              className="bg-white border-gray-300"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </div>
                          <div>
                            <Label htmlFor="password" className="text-gray-700">Password</Label>
                            <Input
                              id="password"
                              type="password"
                              required
                              className="bg-white border-gray-300"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </div>
                          <Button type="submit" className="w-full bg-blue-600 hover:bg-blue-700 text-white">
                            <UserPlus className="mr-2 h-4 w-4" /> Sign Up
                          </Button>
                        </form>
                      </TabsContent>
                    </Tabs>
                    <div className="mt-6">
                      <div className="relative">
                        <div className="absolute inset-0 flex items-center">
                          <Separator className="w-full" />
                        </div>
                        <div className="relative flex justify-center text-sm">
                          <span className="px-2 bg-white text-gray-500">Or continue with</span>
                        </div>
                      </div>
                      <div className="mt-6 grid grid-cols-3 gap-3">
                        <Button variant="outline" onClick={() => handleSocialLogin('google')}>
                          <svg className="h-5 w-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                            <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                            <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                            <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                          </svg>
                        </Button>
                        <Button variant="outline" onClick={() => handleSocialLogin('linkedin')}>
                          <svg className="h-5 w-5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z" fill="#0077B5"/>
                          </svg>
                        </Button>
                        <Button variant="outline" onClick={() => handleSocialLogin('microsoft')}>
                          <svg className="h-5 w-5" viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg">
                            <path fill="#f3f3f3" d="M0 0h23v23H0z" />
                            <path fill="#f35325" d="M1 1h10v10H1z" />
                            <path fill="#81bc06" d="M12 1h10v10H12z" />
                            <path fill="#05a6f0" d="M1 12h10v10H1z" />
                            <path fill="#ffba08" d="M12 12h10v10H12z" />
                          </svg>
                        </Button>
                      </div>
                    </div>
                    {error && (
                      <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded flex items-center">
                        <AlertCircle className="h-5 w-5 mr-2" />
                        <p>{error}</p>
                      </div>
                    )}
                    {isLoading && (
                      <div className="mt-4 text-center">
                        <RefreshCw className="animate-spin h-5 w-5 mx-auto" />
                        <p>Processing...</p>
                      </div>
                    )}
                  </DialogContent>
                </Dialog>
              )}
            </div>
          </nav>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-20 text-center">
        {showUpload && (
          <>
            <h2 className="text-4xl font-bold mb-6 text-gray-900">Calculate Your Gender Pay Gap</h2>
            <p className="text-xl mb-4 text-gray-600">
              Upload your CSV file and get instant results. Ensure compliance and promote equality.
            </p>
          </>
        )}
        
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-2xl mx-auto">
          {showUpload ? (
            <>
              <div className="mb-6">
                <Button onClick={handleDownloadExample} className="mb-6 bg-green-600 hover:bg-green-700 text-white">
                  <Download className="mr-2 h-4 w-4" /> Download CSV Example (5 rows)
                </Button>
                <Label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer hover:bg-gray-50">
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <UploadCloud className="w-10 h-10 mb-3 text-gray-400" />
                    <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                    <p className="text-xs text-gray-500">CSV file (MAX. 10MB, 5 data rows + header)</p>
                  </div>
                  <Input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} accept=".csv" />
                </Label>
              </div>
              {file &&
                <Button 
                  className="w-full text-lg py-6 bg-blue-600 hover:bg-blue-700 text-white" 
                  onClick={handleUpload} 
                  disabled={isLoading || !!error}
                >
                  {isLoading ? 'Calculating...' : 'Calculate Gender Pay Gap'}
                  <ChevronRight className="ml-2 h-5 w-4" />
                </Button>
              }
            </>
          ) : (
            <>
              <div className="flex items-center justify-center mb-6">
                <FileText className="h-8 w-8 text-blue-600 mr-2" />
                <h2 className="text-3xl font-bold text-gray-900">Your Results</h2>
              </div>
              <Button 
                className="w-full text-lg py-6 bg-green-600 hover:bg-green-700 text-white mb-6" 
                onClick={handleTryAgain}
              >
                Try Again with a New File
                <RefreshCw className="ml-2 h-5 w-4" />
              </Button>
            </>
          )}
          {error && (
            <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded flex items-center">
              <AlertCircle className="h-5 w-5 mr-2" />
              <p>{error}</p>
            </div>
          )}
          {calculationResults && (
            <div className="mt-8 text-left">
              <h3 className="text-2xl font-bold mb-4">Results for {calculationResults.organizationName}</h3>
              <div className="overflow-x-auto">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="w-[200px]">Metric</TableHead>
                      <TableHead>Value</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-medium">Mean Gender Pay Gap</TableCell>
                      <TableCell>{formatValue(calculationResults.meanGenderPayGap)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Median Gender Pay Gap</TableCell>
                      <TableCell>{formatValue(calculationResults.medianGenderPayGap)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Mean Bonus Gap</TableCell>
                      <TableCell>{formatValue(calculationResults.meanBonusGap)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Median Bonus Gap</TableCell>
                      <TableCell>{formatValue(calculationResults.medianBonusGap)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Males Receiving Bonus</TableCell>
                      <TableCell>{formatValue(calculationResults.malesBonusPercentage)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Females Receiving Bonus</TableCell>
                      <TableCell>{formatValue(calculationResults.femalesBonusPercentage)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Lower Quartile (Male/Female)</TableCell>
                      <TableCell>{formatValue(calculationResults.lowerQuartileMale)} / {formatValue(calculationResults.lowerQuartileFemale)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Lower Middle Quartile (Male/Female)</TableCell>
                      <TableCell>{formatValue(calculationResults.lowerMiddleQuartileMale)} / {formatValue(calculationResults.lowerMiddleQuartileFemale)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Upper Middle Quartile (Male/Female)</TableCell>
                      <TableCell>{formatValue(calculationResults.upperMiddleQuartileMale)} / {formatValue(calculationResults.upperMiddleQuartileFemale)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Upper Quartile (Male/Female)</TableCell>
                      <TableCell>{formatValue(calculationResults.upperQuartileMale)} / {formatValue(calculationResults.upperQuartileFemale)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            </div>
          )}
        </div>

        {showUpload && (
          <div className="mt-20 grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="flex items-center justify-center mb-4">
                <UploadCloud className="w-10 h-10 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-800">Easy Upload</h3>
              <p className="text-gray-600">Simply drag and drop your CSV file or click to select it from your computer.</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="flex items-center justify-center mb-4">
                <Zap className="w-10 h-10 text-yellow-500" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-800">Instant Results</h3>
              <p className="text-gray-600">Get your gender pay gap calculation results immediately after uploading your file.</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="flex items-center justify-center mb-4">
                <Lock className="w-10 h-10 text-green-600" />
              </div>
              <h3 className="text-xl font-semibold mb-4 text-gray-800">Secure & Private</h3>
              <p className="text-gray-600">Your data is processed securely and never stored on our servers without your permission.</p>
            </div>
          </div>
        )}
      </main>

      <footer className="bg-gray-800 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 text-center">
          <p>&copy; 2023 Gender Pay Gap Calculator. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}